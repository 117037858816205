import React, { useEffect, useState } from "react"
import Button from "../atoms/Button"

const FeaturesFreeFallElementsSection = ({ data }) => {
  
  const [animatedTitle, setAnimatedTitle] = useState("");

  useEffect(() => {

    if (data && data.title) {
      // Wrap each letter in a span with an incrementing index, preserving spaces
      let count = 1;
      const wrappedTitle = data.title
        .split(" ")
        .map((word, wordIndex) => {

          const wrappedWord = word
          .split("")
          .map((char, charIndex) => {
            return `<span class="text-animate" style="--char-index: ${ count++ }">${char}</span>`;
          })
          .join("");

          // Wrap spaces in a span with a specific class for spacing
          return `<span class="word inline-flex nowrap">${wrappedWord}</span> `;
        })
        .join("");

      setAnimatedTitle(wrappedTitle);
    }


    // Function to load a script
    const loadScript = src => {
      return new Promise((resolve, reject) => {
        // Check if the script is already loaded
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve() // If already loaded, resolve the promise
          return
        }

        const script = document.createElement("script")
        script.src = src
        script.async = true
        script.onload = resolve
        script.onerror = reject
        script.setAttribute("data-loaded-script", src) // Add an attribute to identify the script
        document.body.appendChild(script)
      })
    }

    // Load scripts in order
    const loadScriptsInOrder = async () => {
      try {
        await loadScript("https://code.jquery.com/jquery-3.6.0.min.js")

        await loadScript(
          "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.5/gsap.min.js"
        )

        await loadScript("/assets/matter.min.js")

        await loadScript("/assets/liquid-throwable.js")
      } catch (error) {
        console.error("Error loading scripts:", error)
      }
    }

    loadScriptsInOrder()

    return () => {
      // Remove all scripts added by this effect
      const addedScripts = document.querySelectorAll(
        "script[data-loaded-script]"
      )
      addedScripts.forEach(script => {
        document.body.removeChild(script)
      })
    }
  }, [])

  return (
    <>
      <div className="bg-[#0A1E46]">
        <div className="synkli-section--horizontal-space synkli-section--vertical-space relative z-[3]">
          <h2
            className={`text-animate text-[32px] md:text-[45px] lg:text-[60px] leading-[1.2em] font-[600]`}
            dangerouslySetInnerHTML={{ __html: animatedTitle }}
          />

          {data.button && (
            <Button
              to={data.button?.to || "/"}
              href={data.button?.href || ""}
              text={data.button?.text || ""}
              ec={
                data.button?.ec +
                  " secondary-btn px-6 lg:px-[50px] !py-2 lg:!py-3 block max-w-[220px] !mt-10" ||
                ""
              }
            />
          )}
        </div>

        <div className="overflow-hidden mt-[-180px] md:mt-[-300px] relative z-[2]">
          <div
            className="lqd-throwable-scene min-h-[500px] relative"
            data-lqd-throwable-scene="true"
          >
            {data.featureElements.map((element, index) => (
              <p
                className={`lqd-throwable-element`}
                data-lqd-throwable-el=""
                key={`featureelement_` + index}
              >
                <span className={`lqd-throwable-element-rot ${element.ec}`}>
                  {element.text}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default FeaturesFreeFallElementsSection
