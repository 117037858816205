import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturesSection from "../components/common/FeaturesSection"
import {
  featuresFreeFallElementsSection,
  featuresSection,
  heroSection,
} from "../Content/FeaturesPage"
import InnerPageHeroSection from "../components/common/InnerPageHeroSection"
import FeaturesFreeFallElementsSection from "../components/common/FeaturesFreeFallElementsSection"

const Features = () => (
  <Layout>
    <Seo
      title="Synkli All Features"
      description={`Discover all the features that Synkli offers to help accountants collect client data digitally for smoother accounting practice management, enhancing efficiency and collaboration.`}
    />

    <InnerPageHeroSection
      title={heroSection.title}
      description={heroSection.description}
      image={{
        path: heroSection.image.path,
        alt: heroSection.image.alt,
      }}
    />

    <FeaturesFreeFallElementsSection data={featuresFreeFallElementsSection} />

    <div id="our_features_section">
      <FeaturesSection
        title={featuresSection.title}
        features={featuresSection.features}
      />
    </div>
  </Layout>
)

export default Features
